import { PUBLIC_BUCKET_NAME } from "../Configurl/live";
import { endpoint } from "./CommonApi";

export const HandleFatchImages = (path, name) => {
  if (process.env.REACT_APP_ENV === "live") {
    const LIVE_ImageUrl = `${endpoint}/${path}/${name}`;
    return LIVE_ImageUrl;
  } else {
    const PROD_ImageUrl = `https://${PUBLIC_BUCKET_NAME}/${path}/${name}`;
    return PROD_ImageUrl;
  }
};
