// import { toast } from "react-toastify";
import config from "../utils/configloader";

export const endpoint = config.apiUrl;

export const myProfileApi = endpoint + "/myprofile/personaldetail";
// export const myProfileApi = endpoint + "/candidateprofile/profile";

// export const commonSkillsApi = endpoint + "/common/skills/all";
export const commonSkillsApi = endpoint + "/common/skills/all";

export const apiToken = localStorage.getItem("key1");

// this here I had call a common getItem and try to import but there is an issue that whenever I import i have to refresh the page to get the localStorage userType.
export const userType = localStorage.getItem("userType");

// This is For Alert Notification..! //
// export const SHOW_TOAST_SUCCESS = (message)   => {
//   toast.success(message, {
//     position: toast.POSITION.BOTTOM_RIGHT,
//   });
// };
// export const SHOW_TOAST_ERROR = (message) => {
//   toast.error(message, {
//     position: toast.POSITION.BOTTOM_RIGHT,
//   });
// };
// export const SHOW_TOAST_WARNING = (message) => {
//   toast.warning(message, {
//     position: toast.POSITION.BOTTOM_RIGHT,
//   });
// };
// export const SHOW_TOAST_INFO = (message) => {
//   toast.info(message, {
//     position: toast.POSITION.BOTTOM_RIGHT,
//   });
// };
