import React from "react";
import useMediaQuery from "@mui/material/useMediaQuery";
import Button from "@mui/material/Button";
import Box from "@mui/material/Box";
import ErrorImage from "../WaitingScreen/Photos/Error404.png";
import { useTheme } from "@mui/material/styles";
import { genericdarkshade } from "../../API/GenericApi";

const PageNotFound = () => {
  const theme = useTheme();
  const matchDownMd = useMediaQuery(theme.breakpoints.down("sm"));

  const handleGoBack = () => {
    window.history.back();
  };
  return (
    <Box
      color={"#b4b3b5"}
      sx={{
        paddingTop: matchDownMd ? "140px" : "0px",
        display: "flex",
        flexDirection: "column",
      }}
    >
      <img
        src={ErrorImage}
        style={{
          height: matchDownMd ? "80%" : "580px",
          width: matchDownMd ? "80%" : "580px",
          margin: "0px auto",
        }}
        alt="ErrorImg"
      />
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <Button
          variant="contained"
          style={{ ...genericdarkshade, borderRadius: "15px" }}
          sx={{
            "&:hover": {
              background: "#33bbc5",
            },
          }}
          onClick={handleGoBack}
        >
          GO Back
        </Button>
      </div>
    </Box>
  );
};

export default PageNotFound;
