import { apiToken, myProfileApi } from "../API/CommonApi";
// SHOW_TOAST_ERROR,
// SHOW_TOAST_SUCCESS,
import axios from "axios";
import GenericErrorHandler from "./GenericErrorHandler";

// API Calling without giving token in header
export const NoTokenApicall = async (url, val) => {
  return axios
    .post(url, {
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      timeout: 8000, //8 second timeout
      body: JSON.stringify(val),
    })
    .catch((error) => {
      GenericErrorHandler(error);
    });
};

export const ApiTokenCall = async (url, val) => {
  return await axios
    .post(url, val, {
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: `Bearer ${apiToken}`,
      },
      body: JSON.stringify(val),
      timeout: 8000, //  //8 second timeout
    })
    .then((response) => response.data)
    .catch((error) => {
      GenericErrorHandler(error);
    });
};
// Api calling without body
export const ApiCallWObody = async (url) => {
  return await axios(url, {
    method: "POST",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      Authorization: `Bearer ${apiToken}`,
    },
    timeout: 8000, //  //8 second timeout
  })
    .then((response) => response.data)
    .catch((error) => {
      GenericErrorHandler(error);
    });
};

// Below function is used to upload files
export const uploadFileFunc = async (url, data) => {
  return axios
    .post(url, data, {
      headers: {
        // Accept: "application/json",
        Authorization: `Bearer ${apiToken}`,
      },
      timeout: 8000, //8 second timeout
    })
    .then((response) => response.data)
    .catch((error) => {
      GenericErrorHandler(error);
    });
};

// Below function is going to fetch whole My Profile page details
export const profileApi = async (data) => {
  return axios(myProfileApi, {
    method: "POST",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      Authorization: `Bearer ${apiToken}`,
    },
    body: JSON.stringify(data),
  }).catch((error) => {
    GenericErrorHandler(error);
  });
};

// GET method
export const getMethodAPI = async (url) => {
  return axios
    .get(url, {
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: `Bearer ${apiToken}`,
      },
      timeout: 8000, //8 second timeout
    })
    .then((response) => response.data)
    .catch((error) => {
      GenericErrorHandler(error);
    });
};

export const commonSkillAll = async (url) => {
  return axios(url, {
    method: "POST",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      Authorization: `Bearer ${apiToken}`,
    },
    timeout: 8000, //8 second timeout
  })
    .then((response) => response.data)
    .catch((error) => {
      GenericErrorHandler(error);
    });
};

export const BlobApiTokenCall = async (url, val) => {
  return await axios
    .post(url, val, {
      responseType: "blob",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: `Bearer ${apiToken}`,
      },
      body: JSON.stringify(val),
      timeout: 8000, // //8 second timeout
    })
    .then((response) => response.data)
    .catch((error) => {
      GenericErrorHandler(error);
    });
};

export const fetchImageApi = async (url, val) => {
  return await axios
    .post(url, val, {
      responseType: "blob",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${apiToken}`,
      },
      body: JSON.stringify(val),
      timeout: 8000, // //8 second timeout
    })
    .then((response) => response.data)
    .catch((error) => {
      GenericErrorHandler(error);
    });
};

//  For Background-color
export const genericlightshade = {
  backgroundColor: "#c1eaed",
};

export const genericdarkshade = {
  backgroundColor: "#33bbc5",
};
export const genericfont = {
  fontFamily: "Open Sans",
};
