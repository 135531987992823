import React from "react";
import { useRef, useState, useEffect } from "react";
import AppBar from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import Typography from "@mui/material/Typography";
import { ThemeProvider, createTheme } from "@mui/material/styles";
import Grid from "@mui/material/Grid";
import { endpoint } from "../API/CommonApi";
import Quickhirez from "./WaitingScreen/Photos/QuickHireZ.png";
import { genericlightshade } from "../API/GenericApi";
import Box from "@mui/material/Box";
import Popper from "@mui/material/Popper";
import ContactSupportOutlinedIcon from "@mui/icons-material/ContactSupportOutlined";
import CallOutlinedIcon from "@mui/icons-material/CallOutlined";
import MailOutlinedIcon from "@mui/icons-material/MailOutlined";
import Stack from "@mui/material/Stack";
import ClickAwayListener from "@mui/material/ClickAwayListener";
import { useTheme } from "@mui/material/styles";
import Transitions from "../Components/Ui-Component/Transistion";
import Card from "@mui/material/Card";
import { TimeContext } from "../TimeContextApi/TimeProvider";
import { useContext } from "react";
import { getData } from "../API/Secure";
import { IMAGE_URL } from "../utils/configloader";
import { HandleFatchImages } from "../API/S3_Data_Generic";
function AppBarLabel() {
  const theme = useTheme();
  const [openhelp, setOpenhelp] = useState(false);
  const HelpRef = useRef(null);
  const helpopen = useRef(null);
  const image = getData("logo");
  const systemEmail = getData("systemEmail");
  const systemMobileNumber = getData("systemMobileNumber");

  const { logoShow } = useContext(TimeContext);
  const closedialog = (event) => {
    if (HelpRef.current && HelpRef.current.contains(event.target)) {
      return;
    }
    setOpenhelp(false);
  };

  const togglehelp = () => {
    setOpenhelp((helpopen) => !helpopen);
  };

  useEffect(() => {
    if (helpopen.current === true && openhelp === false) {
      HelpRef.current.focus();
    }

    helpopen.current = openhelp;
  }, [openhelp]);

  const path =
    process.env.REACT_APP_ENV === "live"
      ? "join/organization/image"
      : IMAGE_URL;

  return (
    <Toolbar sx={genericlightshade}>
      <Grid container alignItems="center">
        <Grid item xs={6} md={6} lg={6}>
          <Typography
            variant="h6"
            noWrap
            component="div"
            sx={{
              flexGrow: 1,
              cursor: "pointer",
              display: "flex",
              alignItems: "center",
            }}
          >
            <img
              src={Quickhirez}
              alt="logo"
              height="70px"
              width="120px"
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
              }}
            />
          </Typography>
        </Grid>
        <Grid item xs={6} md={6} lg={6}>
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              justifyContent: "flex-end",
            }}
          >
            <Box
              sx={{
                paddingLeft: "550px",
              }}
            >
              <ContactSupportOutlinedIcon
                ref={HelpRef}
                fontSize="large"
                aria-controls={openhelp ? "menu-list-grow" : undefined}
                onClick={togglehelp}
              />
              <Popper
                open={openhelp}
                anchorEl={HelpRef.current}
                role={undefined}
                transition
                sx={{ zIndex: 10 }}
                popperOptions={{
                  modifiers: [
                    {
                      name: "offset",
                      options: {
                        offset: [0, 14],
                      },
                    },
                  ],
                }}
              >
                {({ TransitionProps }) => (
                  <Transitions in={openhelp} {...TransitionProps}>
                    <ClickAwayListener onClickAway={closedialog}>
                      <Card
                        border={false}
                        elevation={16}
                        content={false}
                        boxShadow
                        shadow={theme.shadows[16]}
                        sx={{
                          marginTop: "10px",
                          borderRadius: "10px",
                        }}
                      >
                        <Box sx={{ p: 3, backgroundColor: "white" }}>
                          <Stack>
                            <Stack
                              direction="row"
                              spacing={0.5}
                              alignItems="center"
                            >
                              <Box
                                sx={{
                                  marginRight: 5,
                                  borderRadius: "6px",
                                }}
                              >
                                <Box sx={{ display: "flex" }}>
                                  <CallOutlinedIcon
                                    fontSize="small"
                                    sx={{ fontWeight: "bold" }}
                                  />
                                  <Typography
                                    component="p"
                                    sx={{
                                      paddingLeft: "3px",
                                      fontSize: "15px",
                                      paddingBottom: "5px",
                                      fontWeight: "bold",
                                    }}
                                  >
                                    {systemMobileNumber === null ||
                                    systemMobileNumber === undefined ||
                                    systemMobileNumber === "null" ||
                                    systemMobileNumber === "undefined"
                                      ? ""
                                      : systemMobileNumber}
                                  </Typography>
                                </Box>
                                <Box sx={{ display: "flex" }}>
                                  <MailOutlinedIcon
                                    fontSize="small"
                                    sx={{ fontWeight: "bold" }}
                                  />
                                  <Typography
                                    component="p"
                                    sx={{
                                      paddingLeft: "3px",
                                      fontSize: "15px",
                                      fontWeight: "bold",
                                    }}
                                  >
                                    {systemEmail === null ||
                                    systemEmail === undefined ||
                                    systemEmail === "null" ||
                                    systemEmail === "undefined"
                                      ? ""
                                      : systemEmail}
                                  </Typography>
                                </Box>
                              </Box>
                            </Stack>
                          </Stack>
                        </Box>
                      </Card>
                    </ClickAwayListener>
                  </Transitions>
                )}
              </Popper>
            </Box>
            {logoShow && (
              <Box
                sx={{
                  flexGrow: 1,
                  cursor: "pointer",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "flex-end", // Align to the right
                }}
              >
                <img
                  src={HandleFatchImages(path, image)}
                  alt="OrgLogo"
                  height="70px"
                  width="120px"
                  style={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                  }}
                />
              </Box>
            )}
          </Box>
        </Grid>
      </Grid>
    </Toolbar>
  );
}

const darkTheme = createTheme({
  palette: {
    primary: {
      main: "#fafafa",
    },
  },
});

export default function Navbar() {
  return (
    <ThemeProvider theme={darkTheme}>
      <AppBar position="sticky" top="0" color="primary" enableColorOnDark>
        {AppBarLabel("enableColorOnDark")}
      </AppBar>
    </ThemeProvider>
  );
}
