import React from "react";
import { createContext, useState } from "react";

export const TimeContext = createContext([]);
export default function TimeProvider({ children }) {
  let [time, setTime] = useState([]);
  const [checkBoxClicked, setCheckBoxClicked] = useState(false);
  const [draggedskillsCheck, setdraggedskillsCheck] = useState(false);
  const [interviewDurationCheck, setInterviewDurationCheck] = useState(false);
  const [interviewDuration, setInterviewDuration] = useState({
    duration: "",
    switchCount: "",
  });
  const [logoShow, setLogoShow] = useState(false);
  return (
    <TimeContext.Provider
      value={{
        time,
        setTime,
        checkBoxClicked,
        setCheckBoxClicked,
        draggedskillsCheck,
        setdraggedskillsCheck,
        interviewDurationCheck,
        setInterviewDurationCheck,
        // interviewDurationCheck,
        interviewDuration,
        setInterviewDuration,
        logoShow,
        setLogoShow,
      }}
    >
      {children}
    </TimeContext.Provider>
  );
}
