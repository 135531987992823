import React, { useEffect, useState } from "react";
import useMediaQuery from "@mui/material/useMediaQuery";
import { endpoint } from "../../API/CommonApi";
import Box from "@mui/material/Box";
import { fetchImageApi } from "../../API/GenericApi";
import { useTheme } from "@mui/material/styles";
import { ConsoleLogger } from "../../Environment/ConsoleLogger";

const NoInternet = (props) => {
  const theme = useTheme();
  const matchDownMd = useMediaQuery(theme.breakpoints.down("sm"));

  const [isOnline, setOnline] = useState(true);
  useEffect(() => {
    setOnline(navigator.onLine);
  }, []);

  useEffect(() => {
    const fetchImage = async () => {
      try {
        const data = {};
        let path = "/common/network/error";
        let url = endpoint + path;
        const response = await fetchImageApi(url, data);
        ConsoleLogger(
          "NoInternet::fetchImage sucessfully data fetch from response",
          response
        );
        if (response) {
          const imageUrl = URL.createObjectURL(response);
          localStorage.setItem("image", imageUrl);
        }
      } catch (error) {
        console.error("Error fetching image:", error);
      }
    };
    fetchImage();
  }, []);

  window.addEventListener("online", () => {
    setOnline(true);
  });

  window.addEventListener("offline", () => {
    setOnline(false);
  });

  if (isOnline) {
    return props.children;
  } else {
    return (
      <Box
        color={"#b4b3b5"}
        sx={{
          paddingTop: matchDownMd ? "140px" : "0px",
          display: "flex",
          flexDirection: "column",
        }}
      >
        <img
          src={localStorage.getItem("image")}
          style={{
            height: matchDownMd ? "80%" : "580px",
            width: matchDownMd ? "80%" : "580px",
            margin: "0px auto",
          }}
          alt="NoInternetImg"
        />
      </Box>
    );
  }
};

export default NoInternet;
