const loadConfig = () => {
  switch (process.env.REACT_APP_ENV) {
    case "live":
      return require("../Configurl/live").default;
    case "qa":
      return require("../Configurl/qa").default;
    case "prod":
      return require("../Configurl/prod").default;
    default:
      throw new Error("Unknown environment");
  }
};

export default loadConfig();

const config = loadConfig();

export const IMAGE_URL = config.IMAGE_URL_S3;
