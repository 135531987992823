import axios from "axios";
import { ConsoleLogger } from "../Environment/ConsoleLogger";

const GenericErrorHandler = (error) => {
  ConsoleLogger(
    "GenericErrorHandler::error occur during genericerror from error",
    error
  );

  const errorList = [];
  if (axios.isCancel(error) || error.message === "timeout of 5000ms exceeded") {
    // Request was canceled due to timeout
    throw new Error("Request canceled due to timeout");
  } else if (error.message === "Network Error") {
    throw new Error("some network issus, please try again");
  } else if (
    error.response &&
    error.response.status === 400 &&
    error.code === "ERR_BAD_REQUEST"
  ) {
    throw new Error("Something went wrong with the request");
  } else if (error.response) {
    // The request was made and the server responded with a status code outside the range of 2xx
    if (
      error.response.status === 400 &&
      error.response.data.message === "Validation Failed"
    ) {
      error.response.data.details.forEach((detail) => {
        errorList.push(detail);
      });
      // eslint-disable-next-line no-throw-literal
      throw { isListError: true, errorDetails: errorList };
    } else {
      throw new Error(`  ${error.response.data.message}`);
    }
  } else if (error.request) {
    // The request was made but no response was received
    throw new Error("No response received from the server");
  } else {
    // Something happened while setting up the request
    throw new Error(
      "Network connection failed or an error occurred during the request"
    );
  }
};
export default GenericErrorHandler;

//********************Generic Alert Message***/

export const downloadMsg = "Resume Not Available";
