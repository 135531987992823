import CryptoJS from "crypto-js";

const secretKey = " ajQennY4VLuBLkgg3igJgMiKi0Ko2DfLElmXNAJRDrg=";
// Function to encrypt data
export const encryptData = (data) => {
  return CryptoJS.AES.encrypt(data, secretKey).toString();
};

// Function to decrypt data
export const decryptData = (cipherText) => {
  const bytes = CryptoJS.AES.decrypt(cipherText, secretKey);
  return bytes.toString(CryptoJS.enc.Utf8);
};

// Function to hash keys
export const hashKey = (key) => {
  return CryptoJS.SHA256(key).toString();
};
export const storeUserId = (key, value) => {
  const hashedKey = hashKey(key);
  localStorage.setItem(hashedKey, value);
};

export const getUserId = (key) => {
  const hashedKey = hashKey(key);
  const encryptedValue = localStorage.getItem(hashedKey);
  if (encryptedValue) {
    return encryptedValue;
  }
  return null;
};

// Function to store data in localStorage with encrypted key and value
export const storeData = (key, value) => {
  const hashedKey = hashKey(key);
  const encryptedValue = encryptData(value);
  localStorage.setItem(hashedKey, encryptedValue);
};
//remove item  from localstorage
export const removeData = (key) => {
  const hashedKey = hashKey(key);
  const encryptedValue = localStorage.removeItem(hashedKey);
};

// Function to retrieve data from localStorage with encrypted key and value
export const getData = (key) => {
  const hashedKey = hashKey(key);
  const encryptedValue = localStorage.getItem(hashedKey);
  if (encryptedValue) {
    return decryptData(encryptedValue);
  }
  return null;
};
