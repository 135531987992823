import React, { useState, useEffect } from "react";
import Error from "../../src/Images/error.png";

function ErrorBoundary({ children }) {
  const [hasError, setHasError] = useState(false);

  // Handle errors and set hasError to true
  useEffect(() => {
    window.addEventListener("error", () => {
      setHasError(true);
    });

    return () => {
      window.removeEventListener("error", () => {
        setHasError(true);
      });
    };
  }, []);

  if (hasError) {
    return (
      <div
        style={{
          position: "fixed",
          top: 0,
          left: 0,
          width: "100%",
          height: "100%",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          backgroundColor: "#fff",
          objectFit: "contain",
        }}
      >
        <img
          src={Error}
          alt="Error"
          style={{
            maxWidth: "100%",
            maxHeight: "100%",
            border: "none",
          }}
        />
      </div>
    );
  }

  return children;
}

export default ErrorBoundary;
