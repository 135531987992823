import React, { lazy, Suspense } from "react";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import Navbar from "./Components/Navbar";
import TimeProvider from "./TimeContextApi/TimeProvider";
import PageNotFound from "./Components/ErrorPages/PageNotFound";
import NoInternet from "./Components/ErrorPages/NoInternet";
import ErrorBoundary from "./utils/ErrorBoundry";

// Lazy load your components
const InterviewJoin = lazy(() =>
  import("./Components/TermsComponent/InterviewJoin")
);
const TermsPage = lazy(() => import("./Components/TermsComponent/TermsPage"));
const WScreen = lazy(() =>
  import("./Components/WaitingScreen/Panelist/WScreen")
);
const MainCandi = lazy(() =>
  import("./Components/WaitingScreen/Candidate/MainCandi")
);
const IntScreen = lazy(() =>
  import("./Components/InterviewScreen/Panelist/IntScreen")
);
const MainScreen = lazy(() =>
  import("./Components/InterviewScreen/Candidate/MainScreen")
);
const MainFeed = lazy(() => import("./Components/Feedback/Panelist/MainFeed"));
const CandiFeedback = lazy(() =>
  import("./Components/Feedback/Candidate/CandiFeedback")
);
const Submit = lazy(() =>
  import("./Components/InterviewScreen/Panelist/End.js/Submit")
);
const Main = lazy(() => import("./Components/ThankyouScreen/Main"));

function App() {
  return (
    <>
      <TimeProvider>
        <Navbar />
        <NoInternet>
          <ErrorBoundary>
            <Router>
              <Suspense fallback={<div>Loading...</div>}>
                <Routes>
                  {/* to do when we add dashbord  then not show 404 page default */}
                  <Route exact path="/*" element={<PageNotFound />} />
                  <Route
                    exact
                    path="/join/:roomId/:roomInterviewUserId"
                    element={<InterviewJoin />}
                  />
                  <Route exact path="/termspage" element={<TermsPage />} />
                  <Route path="/panelist/waitingscreen" element={<WScreen />} />
                  <Route
                    path="/candidate/waitingscreen"
                    element={<MainCandi />}
                  />
                  <Route path="/interviewscreen" element={<IntScreen />} />
                  {/* Candidate Screen */}
                  <Route path="/interviewscreen2" element={<MainScreen />} />
                  <Route path="/feedback" element={<MainFeed />} />
                  <Route
                    path="/candidatefeedback"
                    element={<CandiFeedback />}
                  />
                  <Route path="/submit" element={<Submit />} />
                  <Route path="/thankyou" element={<Main />} />
                </Routes>
              </Suspense>
            </Router>
          </ErrorBoundary>
        </NoInternet>
      </TimeProvider>
    </>
  );
}

export default App;
